
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { getIllustrationsPath } from "@/core/helpers/assets";
  import { reactive } from "vue";
  import { ref } from "vue";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { getMerchantsRequest } from "@/latipay/apis/services/MerchantsService";
  import { ElMessage } from "element-plus";
  import useClipboard from 'vue-clipboard3'
  export default defineComponent({
    name: "merchants",
    components: {},
    setup() {
      const store = useStore();


      const id = ref<string | null>(null);
      const slug = ref<string | null>(null);
      const name = ref<string | null>(null);
      const type = ref<string | null>(null);
      const status = ref<string | null>(null);
      const enabled = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);
      const sort = ref<string | null>(null);

      const loading = ref(false);

      const { toClipboard } = useClipboard();
      const formInline = reactive({
        id: "",
        slug: "",
        name: "",
        type: "",
        status: "",
        enabled: null
      });

      const {
        merchantsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getMerchants
      } = getMerchantsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getMerchants(
          formInline.id,
          formInline.slug,
          formInline.name,
          formInline.type,
          formInline.status,
          formInline.enabled,

          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(merchantsRef);
      };

      console.log(merchantsRef);

      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };

      const copySlug = async (slug: string) => {
        try {
          await toClipboard(slug);
          ElMessage({
            showClose: true,
            message: 'Copy successfully!',
            type: "success"
          });
        } catch (e) {
          console.error(e)
        }
      };


      return {
        getIllustrationsPath,
        formInline,
        id,
        slug,
        name,
        type,
        status,
        copySlug,
        enabled,
        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        merchantsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef


      };

    },
    data() {
      return {};
    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();
        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }

  });
